@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

*,
body {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
}

/* Small Devices (Tablets) */
@media only screen and (min-width: 577px) and (max-width: 767px) {
}

/* Medium Devices (Laptops, Desktops) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (Large Laptops, Desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 1200px) {
}
