.galleryWrap {
    display: flex;
    flex-wrap: wrap;
    gap: 5px;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 20px;
    
  }

  .gallery-h1{
    color: black;
    padding-top: 30px;
    font-size: 2rem;
    text-transform: uppercase;

  }
  
  .galleryWrap .single {
    width: 200px;
    cursor: pointer;
  }
  
  .galleryWrap .single img {
    max-width: 100%;
  }
  
  .galleryWrap .single img:hover {
    transform: scale(1.02);
  }
  
  .sliderWrap {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
  
  .sliderWrap .btnClose, .sliderWrap .btnPrev, .sliderWrap .btnNext {
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    color: #fff;
    z-index: 9999;
  }
  
  .btnNext:hover, .btnPrev:hover, .btnClose:hover {
    opacity: 1;
  }
  
  .sliderWrap .btnClose {
    top: 40px;
    right: 40px;
  }
  
  .sliderWrap .btnPrev {
    top: 50%;
    transform: translateY(-50%);
    left: 40px;
  }
  
  .sliderWrap .btnNext {
    top: 50%;
    transform: translateY(-50%);
    right: 40px;
  }
  
  .fullScreenImage {
    width: calc(100% - 40px);
    height: calc(100% - 40px);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .fullScreenImage img {
    max-width: 100%;
    max-height: 100%;
    pointer-events: none;
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
    user-select: none; /* Standard syntax */  
  }


  
 /* Extra Small Devices (Phones) */
 @media only screen and (max-width: 576px) {
    .galleryWrap .single {
        width: 100px;
      }

      .gallery-h1{
        color: black;
        padding-top: 30px;
        font-size: 18px;
        
      }
   
 }
 
 /* Small Devices (Tablets) */
 @media only screen and (min-width: 577px) and (max-width: 767px) {
    .galleryWrap .single {
        width: 100px;
      }

      .gallery-h1{
        font-size: 20px;
        
      }
   
 }
 
 /* Medium Devices (Laptops, Desktops) */
 @media only screen and (min-width: 768px) and (max-width: 991px) {
 
   
 }
 
 /* Large Devices (Large Laptops, Desktops) */
 @media only screen and (min-width: 992px) and (max-width: 1199px) {
  
   
 }
 
 /* Extra Large Devices (Large Desktops) */
 @media only screen and (min-width: 1200px) {

   
 }
 