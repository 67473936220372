.pbtn {
  background: #920a23;
  color: #fff;
  z-index: 5;
  padding: 10px 15px;
  font-size: 18px;
  border-radius: 5px;
  animation: contentAppear 1s linear 1;
  margin-top: 80px;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 20px;
  transition: 0.5s;
}

.pbtn:hover {
  background: #5c101e;
  color: #fff;
  border: 2px solid #fff;
}
