nav {
  width: 100%;
  height: 80px;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  background: #111;
}

.logojbc {
  width: 53px;
  height: 53px;
  position: relative;
}

.logojbc img {
  width: 50px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.logojbc::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #cf1460 0%,
    #eb8fa5 50%,
    #f9ed54 60%,
    #6fe686 100%
  );
  top: 0;
  left: 0;
  border-radius: 50%;
  animation: rotate 2s linear infinite;
}

ul {
  display: flex;
}

ul li {
  list-style: none;
}

a {
  position: relative;
  display: block;
  padding: 5px 10px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 16px;
  transition: 0.5s;
  color: #fff;
}

li a span:nth-child(1) {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid yellow;
  border-left: 2px solid yellow;
}

li a span:nth-child(2) {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-top: 2px solid yellow;
  border-right: 2px solid yellow;
}

li a span:nth-child(3) {
  position: absolute;
  content: "";
  bottom: 0;
  left: 0;
  width: 10px;
  height: 10px;
  border-bottom: 2px solid yellow;
  border-left: 2px solid yellow;
}

li a span:nth-child(4) {
  position: absolute;
  content: "";
  bottom: 0;
  right: 0;
  width: 10px;
  height: 10px;
  border-right: 2px solid yellow;
  border-bottom: 2px solid yellow;
}

li a span {
  transform: scale(0);
  transition: 0.5s;
}

li a:hover span {
  transform: scale(1);
}

a:hover {
  color: #ffff00;
}

.checkbtn,
#check {
  display: none;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 458px) {
  nav {
    width: 100%;
  }
  ul {
    position: fixed;
    top: 80px;
    left: 0;
    display: flex;
    align-items: center;
    flex-direction: column;
    z-index: 3;
    background: #111;
    width: 100%;
    height: 100vh;
    transition: 0.5s;
    left: -100%;
    gap: 55px;
    padding-top: 20px;
    overflow: hidden;
  }

  nav ul li a {
    font-size: 26px;
    margin: 10px;
  }

  .checkbtn {
    display: block;
  }

  #check:checked ~ ul {
    left: 0;
  }
}
