.contact-wrapper {
  width: 100%;
  height: 90vh;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact {
  clip-path: polygon(0 0, 100% 0, 100% 52%, 48% 65%, 0 53%);
  background-position: center top;
  background-size: cover;
  width: 100%;
  height: 800px;
}

.contact-wrapper h2 {
  padding-top: 0px;
  z-index: 2;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 10px;
}

.details p {
  color: #000;
  padding: 5px 20px;
  text-align: center;
}

.details img {
  width: 35px;
}

.details h5 {
  color: #000;
  text-align: center;
  display: inline-block;
  padding-left: 5px;
}

.service {
  width: 100%;
  height: 100vh;
}

.iframeWrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
}

.content {
  color: white;
  background-color: #000000ee;
  width: 300px;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-items: center;
  padding: 30px;
  text-align: center;
}

.note {
  font-size: 24px;
  text-transform: uppercase;
  color: #f8cf16;
  font-weight: bold;
}

.continue {
  background-color: #f8cf16;
  color: #000;
  border-radius: 15px;
  font-weight: bold;
}

.text {
  font-size: 16px;
}

.modalii {
  position: absolute;
}
.loading {
  display: block;
  text-align: center;
  padding: 20px;
}

.contact {
  display: flex;
  background-color: lightgray;
  border-radius: 10px 10px 0px 0px;
  box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.493);
  -webkit-box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.466);
  border: 2px solid #fff;
}

.imgSide {
  width: 50%;
  background: url(../Gallery/31.jpg);
  background-position: center;
  background-size: cover;
  border-radius: 10px 0px 0px 0px;
}

.detailsSide {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 150px 0px;
}

.detailsSide h1 {
  font-size: 2rem;
  text-shadow: 0px 0px 10px rgba(16, 2, 2, 0.506);
  color: #fff;
}

.detailsSide p,
i {
  font-size: 2rem;
}

.contact a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
  transition: all 0.5s;
}

.contact a:hover {
  color: rgb(73, 73, 65);
}

/* Extra Small Devices (Phones) */
@media only screen and (max-width: 576px) {
  .contact {
    width: 100%;
    justify-content: start;
    flex-direction: column;
    align-items: center;
  }

  .detailsSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
  }

  .detailsSide h1 {
    font-size: 20px;
    text-transform: uppercase;
  }

  .detailsSide p,
  i {
    font-size: 1rem;
  }
}

/* Small Devices (Tablets) */
@media only screen and (min-width: 577px) and (max-width: 767px) {
  .detailsSide {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 0px;
  }

  .detailsSide h1 {
    font-size: 20px;
    text-transform: uppercase;
  }

  .detailsSide p,
  i {
    font-size: 1rem;
  }
}

/* Medium Devices (Laptops, Desktops) */
@media only screen and (min-width: 768px) and (max-width: 991px) {
}

/* Large Devices (Large Laptops, Desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
}

/* Extra Large Devices (Large Desktops) */
@media only screen and (min-width: 900px) {
  .contact {
    display: none;
  }
}
