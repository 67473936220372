.scroller {
    width: 100%;
  }
  
  .scrollerI {
    margin-top: 1rem;
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    
    
  }
 


  .scrollerI img{
    width: 200px;
    border-radius: 20px 0px 20px 0px;
    background-size: cover;
    background-position: center;
    box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
    -webkit-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 0px 8px 2px rgba(0,0,0,0.75);
    border: 2px solid #fff;
  } 
  

 
  
  .scrollerI {
    padding-block: 1rem;
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  
  .scroller[data-animated="true"] {
    overflow: hidden;
    
  }
  
  .scroller[data-animated="true"] .scrollerI {
    width: max-content;
    flex-wrap: nowrap;
    animation: scroll var(--_animation-duration, 0s)
      var(--_animation-direction, forwards) linear infinite;
  }
  
  .scroller[data-direction="right"] {
    --_animation-direction: reverse;
  }
  
  .scroller[data-direction="left"] {
    --_animation-direction: forwards;
  }
  
  .scroller[data-speed="fast"] {
    --_animation-duration: 20s;
  }
  
  .scroller[data-speed="slow"] {
    --_animation-duration: 40s;
    
  }
  
  @keyframes scroll {
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }
  










  
 

  /* Extra Small Devices (Phones) */
 @media only screen and (max-width: 576px) {
  .scrollerI img{
    width: 150px;
    
  }
   
 }
 
 /* Small Devices (Tablets) */
 @media only screen and (min-width: 577px) and (max-width: 767px) {
  .scrollerI img{
    width: 150px;
    
  }
   
 }
 
 /* Medium Devices (Laptops, Desktops) */
 @media only screen and (min-width: 768px) and (max-width: 991px) {
 
   
 }
 
 /* Large Devices (Large Laptops, Desktops) */
 @media only screen and (min-width: 992px) and (max-width: 1199px) {
  
   
 }
 
 /* Extra Large Devices (Large Desktops) */
 @media only screen and (min-width: 1200px) {

   
 }
 